import { type assets as assetsNS, call, codes, getRequestClient, stid } from "@repo/client";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { HiSolidEye, HiSolidPencil, HiSolidTrash } from "solid-icons/hi";
import { TbAlertCircle, TbDownload, TbEdit } from "solid-icons/tb";
import { TiTick } from "solid-icons/ti";
import { For, Match, Show, Switch, createMemo, createSignal } from "solid-js";
import { Spinner } from "~/components/_original/Spinner";
import { StIcon } from "~/components/icons";
import { InlineAsset } from "~/components/InlineAsset";
import { InlineAssetSummary } from "~/components/InlineAssetSummary";
import { sortObjectArray } from "~/lib/array/sortObjectArray";
import { classNames } from "~/lib/classNames";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { isBrowserViewable } from "~/lib/ui/isBrowserViewable";
import { readableProcessingState } from "~/lib/ui/readableProcessingState";
import styles from "./CollectionAssetTable.module.css";
import { useWire } from "~/wire";
import { useUIState } from "~/ui/UIState";
import { EditAssetModal } from "~/screens/modals/EditAssetModal";
import { DeleteAssetModal } from "~/screens/modals/DeleteAssetModal";
import { originalFilenameToMimeType } from "~/lib/originalFilenameToMimeType";

dayjs.extend(localizedFormat);

type Sort<T> = {
  field: keyof T;
  dir: SortDirection;
};

interface Props {
  collectionName: string;
  data: assetsNS.AssetSnapshot[];
  modifier?: "drawer";
  refreshAssets?: () => void;
}

export const CollectionAssetsTable = (props: Props) => {
  const wire = useWire();
  const state = useUIState();
  const client = getRequestClient(wire.services.identity.getIdentityToken);

  const [sortOrder, setSortOrder] = createSignal<Sort<assetsNS.AssetSnapshot>>({
    field: "modifiedAt",
    dir: "desc",
  });
  const data = createMemo(() => {
    if (!props.data) {
      return [];
    }
    return sortObjectArray<assetsNS.AssetSnapshot>(sortOrder().field, props.data, sortOrder().dir);
  });

  const onClick = (property: keyof assetsNS.AssetSnapshot) => {
    return () => {
      if (sortOrder().field === property) {
        setSortOrder({
          field: property,
          dir: sortOrder().dir === "asc" ? "desc" : "asc",
        });
      } else {
        setSortOrder({
          field: property,
          dir: "asc",
        });
      }
    };
  };

  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;

  /**
   * Click event handler for the inner link button. Used to allow users to
   * click the button and activate the link to view/download an asset source.
   * @param event
   */
  const clickInnerLink = (event: MouseEvent): void => {
    const button = event.currentTarget as HTMLButtonElement;
    const link = button.querySelector("a") as HTMLAnchorElement | null;
    link?.click();
  };

  const changeAttr = async (assetId: string, displayName: string, summary?: string) => {
    const results = await call(async () => {
      if (!assetId) throw new Error("assetID is undefined while attempting to change attributes");
      return await client.controlplane.UpdateAssetAttributes(assetId, {
        assetId,
        displayName,
        summary: summary || "",
      });
    });
    if (results.code !== codes.OK) {
      // send(
      //   deps.eventFactory.newUploadFailedEvent(
      //     `API returned ${results.code} when notifying API of the completed upload`,
      //   ),
      // );
      return;
    }
  };

  const tombstone = async (assetId: string) => {
    const results = await call(async () => {
      if (!assetId) throw new Error("assetID is undefined while attempting to change attributes");
      return await client.controlplane.TombstoneAssets({ assetIds: [assetId] });
    });
    if (results.code !== codes.OK) {
      // send(
      //   deps.eventFactory.newUploadFailedEvent(
      //     `API returned ${results.code} when notifying API of the completed upload`,
      //   ),
      // );
      return;
    }
  };

  return (
    <div
      class={classNames(styles["table"], props.modifier ? styles["table--drawer"] : "")}
      data-component="collection-assets-table"
    >
      <table class={styles["table__container"]}>
        <caption class="screen-reader">{`Table representing the assets associated with the ${formatCollectionName(props.collectionName)}.`}</caption>
        <thead class={styles["table__header"]}>
          <tr class={styles["table__header-row"]}>
            <th class={classNames(styles["table__header-cell"], styles["table__cell--source"])}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "originalFilename"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                onClick={onClick("originalFilename")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Source</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={classNames(styles["table__header-cell"], styles["table__cell--status"])}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "lifecycleState"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                onClick={onClick("lifecycleState")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Status</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={classNames(styles["table__header-cell"], styles["table__cell--description"])}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "displayName"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                onClick={onClick("displayName")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Name</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <Show when={!props.modifier}>
              <th class={classNames(styles["table__header-cell"], styles["table__cell--last-mod"])}>
                <button
                  type="button"
                  class={classNames(
                    styles["table__header-button"],
                    sortOrder().field === "modifiedAt"
                      ? sortOrder().dir === "asc"
                        ? styles["table__header-button--asc"]
                        : styles["table__header-button--desc"]
                      : "",
                  )}
                  onClick={onClick("modifiedAt")}
                >
                  <span class="screen-reader">Sort table by</span>
                  <span class={styles["table__header-label"]}>Last modified</span>
                  <span class={styles["table__sort-indicator"]} />
                </button>
              </th>
              <td class={classNames(styles["table__header-cell"], styles["table__cell--actions"])}>
                <span class={styles["table__header-label"]} />
              </td>
            </Show>
          </tr>
        </thead>
        <tbody class={styles["table__body"]} aria-busy={false}>
          <For each={data()}>
            {(item) => {
              return (
                <tr class={styles["table__row"]} tabIndex={0}>
                  <td
                    class={classNames(styles["table__cell"], styles["table__cell--source"])}
                    title={item.originalFilename}
                  >
                    <InlineAsset
                      name={item.originalFilename}
                      mimeType={originalFilenameToMimeType(item.originalFilename)}
                    />
                  </td>
                  <td class={classNames(styles["table__cell"], styles["table__cell--status"])}>
                    <Switch>
                      <Match when={readableProcessingState(item.lifecycleState, item.modifiedAt) === "Failed"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--failed"])}>
                          <StIcon class={styles["table__cell-status-icon"]} icon={TbAlertCircle} size="1.25rem" />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState, item.modifiedAt)}
                          </span>
                        </p>
                      </Match>
                      <Match when={readableProcessingState(item.lifecycleState, item.modifiedAt) === "Processing"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--processing"])}>
                          <Spinner class={styles["table__cell-status-icon"]} />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState, item.modifiedAt)}
                          </span>
                        </p>
                      </Match>
                      <Match when={readableProcessingState(item.lifecycleState, item.modifiedAt) === "Ready"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--ready"])}>
                          <StIcon class={styles["table__cell-status-icon"]} icon={TiTick} size="1.25rem" />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState, item.modifiedAt)}
                          </span>
                        </p>
                      </Match>
                    </Switch>
                  </td>
                  <td
                    class={classNames(styles["table__cell"], styles["table__cell--description"])}
                    title={item.displayName}
                  >
                    <InlineAssetSummary summary={item.displayName} />
                  </td>
                  <Show when={!props.modifier}>
                    <td class={classNames(styles["table__cell"], styles["table__cell--last-mod"])}>
                      <span class={styles["table__cell-text"]}>{dayjs(item.modifiedAt).format("LL")}</span>
                    </td>
                    <td class={classNames(styles["table__cell"], styles["table__cell--actions"])}>
                      <Show when={item.signedSourceURL}>
                        <button
                          type="button"
                          onClick={clickInnerLink}
                          class={styles["table__cell-action"]}
                          aria-label="Edit"
                        >
                          <a
                            class={styles["table__cell-action"]}
                            href={item.signedSourceURL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span class="screen-reader">
                              {isBrowserViewable(item.originalFilename?.split(".").pop()?.toLowerCase())
                                ? "View file in a new window."
                                : "Download file."}
                            </span>
                            <StIcon
                              aria-hidden="true"
                              icon={
                                isBrowserViewable(item.originalFilename?.split(".").pop()?.toLowerCase())
                                  ? HiSolidEye
                                  : TbDownload
                              }
                              size="1.5rem"
                            />
                          </a>
                        </button>
                      </Show>
                      <button
                        type="button"
                        onClick={() => {
                          setModalOpen("edit-asset");
                          setModalContents(() => () => (
                            <EditAssetModal
                              id={"edit-asset"}
                              onUpdate={async (assetId: string, displayName: string, summary?: string) => {
                                await changeAttr(assetId, displayName, summary);
                                props.refreshAssets?.();
                                setModalOpen("");
                                setModalContents(null);
                              }}
                              onClose={() => {
                                setModalOpen("");
                                setModalContents(null);
                              }}
                              assetId={item.id}
                              displayName={item.displayName}
                              summary={item.summary}
                            />
                          ));
                        }}
                        class={styles["table__cell-action"]}
                        aria-label="Edit"
                      >
                        <StIcon aria-hidden="true" icon={HiSolidPencil} size="1.5rem" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setModalOpen("delete-asset");
                          setModalContents(() => () => (
                            <DeleteAssetModal
                              id={"edit-asset"}
                              onDelete={async () => {
                                await tombstone(item.id);
                                props.refreshAssets?.();
                                setModalOpen("");
                                setModalContents(null);
                              }}
                              onClose={() => {
                                setModalOpen("");
                                setModalContents(null);
                              }}
                              label={item.displayName}
                            />
                          ));
                        }}
                        class={classNames(styles["table__cell-action"], styles["table__cell-action-destructive"])}
                        aria-label="Edit"
                      >
                        <StIcon aria-hidden="true" icon={HiSolidTrash} size="1.5rem" />
                      </button>
                    </td>
                  </Show>
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
